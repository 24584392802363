import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './Upload.css'
import ChessDisplay from './ChessDisplay'
import { Chess } from 'chess.js'

function Upload() {
  const navigate = useNavigate()
  const location = useLocation()
  const [instructionPopup, setInstructionPopup] = useState(true)
  const [invalidFenPopup, setInvalidFenPopup] = useState(false)
  const [creditsPopup, setCreditsPopup] = useState({ show: false, type: '' })
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [uploadResponse, setUploadResponse] = useState(null)
  const [analysisResponse, setAnalysisResponse] = useState(null)
  const [isWhiteMove, setIsWhiteMove] = useState(true)
  const responseRef = useRef(null)
  const [loggedInEmail, setLoggedInEmail] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isFullHint, setIsFullHint] = useState(true)
  const [isGettingAnalysis, setIsGettingAnalysis] = useState(false)
  const [newUpload, setNewUpload] = useState(false)
  const [audioUrl, setAudioUrl] = useState(null)
  const [moveToAnalyze, setMoveToAnalyze] = useState('')
  const [simulatedMoveResponse, setSimulatedMoveResponse] = useState(null)
  const [isAnalyzingMove, setIsAnalyzingMove] = useState(false)
  const [currentFen, setCurrentFen] = useState(null)
  const [game, setGame] = useState(new Chess())
  const [moveSource, setMoveSource] = useState(null) // New state to track move source
  const [withAudio, setWithAudio] = useState(false)
  const [withSimulatedMoveAudio, setWithSimulatedMoveAudio] = useState(false)

  // Authentication and Initialization
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tokenFromUrl = params.get('token')

    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl)
      fetchUserEmail(tokenFromUrl)
    } else {
      const storedToken = localStorage.getItem('token')
      const storedEmail = localStorage.getItem('email')
      if (storedToken && storedEmail) {
        setLoggedInEmail(storedEmail)
        setIsLoggedIn(true)
      } else {
        initializeGoogleSignIn()
      }
    }

    const handlePaste = event => {
      const token = localStorage.getItem('token') // Check token on paste
      if (!token) {
        navigate('/login')
        return
      }

      setAnalysisResponse(null) // Clear previous analysis

      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile()
          const file = new File([blob], 'pasted_image.png', { type: blob.type })
          setFile(file)
          setFileName(file.name)
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(file)
          getCurrentBoard(file, isWhiteMove)
        }
      }
    }

    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [location.search, isWhiteMove, isFullHint, isLoggedIn, navigate])

  // Fetch user email
  const fetchUserEmail = async token => {
    try {
      const response = await axios.get('/api/user-email', {
        headers: { 'x-access-token': token }
      })
      const email = response.data.email
      localStorage.setItem('email', email)
      setLoggedInEmail(email)
      setIsLoggedIn(true)
    } catch (error) {
      console.error('Error fetching user email:', error)
      handleLogout()
    }
  }

  // Initialize Google Sign-In
  const initializeGoogleSignIn = async () => {
    try {
      const response = await axios.get('/api/get-google-client-id-web')
      const clientId = response.data.clientId
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleGoogleSignIn
      })
      window.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
        theme: 'outline',
        size: 'large',
        width: 250 // Match the width of other buttons
      })
    } catch (error) {
      console.error('Error fetching Google Client ID:', error)
    }
  }

  // Handle Google Sign-In response
  const handleGoogleSignIn = async response => {
    if (response.credential) {
      try {
        const result = await axios.post('/api/google-signin', {
          token: response.credential
        })
        if (result.data.token) {
          localStorage.setItem('token', result.data.token)
          localStorage.setItem('email', result.data.email)
          setLoggedInEmail(result.data.email)
          setIsLoggedIn(true)
        }
      } catch (error) {
        console.error('Google Sign-In Error:', error)
      }
    } else {
      console.error('No credential received from Google Sign-In')
    }
  }

  // Close popups
  const closeInstructionPopup = () => setInstructionPopup(false)
  const closeInvalidFenPopup = () => setInvalidFenPopup(false)
  const closeCreditsPopup = () => setCreditsPopup({ show: false, type: '' })

  // Popups Components
  const InstructionPopup = () => (
    <div className="instruction-popup" onClick={closeInstructionPopup}>
      <div className="instruction-content" onClick={e => e.stopPropagation()}>
        <h2>Upload Instructions</h2>
        <ol>
          <li>Drag in a screenshot of a chess game, copy and paste it, or upload it from your files.</li>
          <br />
          <p>Examples of valid chess boards:</p>
          <div className="turn-images">
            <img src={require('./example_screenshot1.png')} alt="Chess.com Screenshot Example" />
            <img src={require('./example_screenshot2.png')} alt="Lichess Screenshot Example" />
          </div>
          <p>You can use a screenshot of the entire page.</p>
          <div className="example-analysis-image">
            <img src={require('./example_screenshot3.png')} alt="Full Chess.com Screenshot Example" />
          </div>
          <br />
          <li>
            Select whose turn it is white or black (the player whose move it is should have started the game at the
            bottom of the board).
            <div className="turn-images">
              <img src={require('./whitetomove.png')} alt="White to Move" />
              <img src={require('./blacktomove.png')} alt="Black to Move" />
            </div>
          </li>
          <br />
          <li>
            Wait for the best move, press get analysis to understand why it's the best move.
            <div className="example-analysis-image">
              <img src={require('./example_bestmove_analysis.png')} alt="Best Move and Analysis Example" />
            </div>
          </li>
        </ol>
        <p className="disclaimer">
          <strong>Disclaimer:</strong> The analysis uses a Large Language Model (LLM) and may not always be accurate or
          reliable. Please use it as a reference only and rely on your own judgment and analysis when making chess
          moves.
        </p>
        <button onClick={closeInstructionPopup}>Close</button>
      </div>
    </div>
  )

  const InvalidFenPopup = () => (
    <div className="instruction-popup" onClick={closeInvalidFenPopup}>
      <div className="instruction-content" onClick={e => e.stopPropagation()}>
        <h2>No chessboard was found in image</h2>
        <p>Please upload a screenshot like the following:</p>
        <div className="turn-images">
          <img src={require('./example_screenshot1.png')} alt="Chess.com Screenshot Example" />
          <img src={require('./example_screenshot2.png')} alt="Lichess Screenshot Example" />
        </div>
        <button onClick={closeInvalidFenPopup}>Close</button>
      </div>
    </div>
  )

  const CreditsPopup = ({ type }) => (
    <div className="instruction-popup" onClick={closeCreditsPopup}>
      <div className="instruction-content" onClick={e => e.stopPropagation()}>
        <h2>Out of Credits</h2>
        <p>
          You have no more {type} available. Please{' '}
          <a href="#" onClick={() => navigate('/checkout')}>
            subscribe
          </a>{' '}
          or{' '}
          <a href="#" onClick={() => navigate('/purchase-credits')}>
            top up your credits with a one-time purchase
          </a>
          .
        </p>
        <button onClick={closeCreditsPopup}>Close</button>
      </div>
    </div>
  )

  // Logout Handler
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    setLoggedInEmail(null)
    setIsLoggedIn(false)
    navigate('/')
  }

  // Drag and Drop Handlers
  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      setIsDragging(true)
    }
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isDragging) setIsDragging(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  // File Selection Handler
  const handleFileChange = async e => {
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      const file = e.target.files[0]
      if (file) {
        setAnalysisResponse(null)
        setFile(file)
        setFileName(file.name)
        if (file.type.startsWith('image/')) {
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(file)
          await getCurrentBoard(file, isWhiteMove)
        } else {
          setFilePreview(null)
        }
      }
    }
  }

  // File Drop Handler
  const handleDrop = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      setIsDragging(false)
      const files = e.dataTransfer.files
      if (files && files.length > 0) {
        setAnalysisResponse(null)
        setFile(files[0])
        setFileName(files[0].name)
        if (files[0].type.startsWith('image/')) {
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(files[0])
          await getCurrentBoard(files[0], isWhiteMove)
        } else {
          setFilePreview(null)
        }
      }
    }
  }

  // Fetch Current Board FEN
  const getCurrentBoard = async (file, whiteToMove = isWhiteMove) => {
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('isWhiteMove', whiteToMove.toString())

        const fenResponse = await axios.post('/api/get-fen', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('token')
          }
        })

        setCurrentFen(fenResponse.data.fen)
        const newGame = new Chess(fenResponse.data.fen)
        setGame(newGame)

        if (window.innerWidth <= 768 && responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error(error)
          setInvalidFenPopup(true)
        } else {
          console.error('Error:', error)
        }
      }
    }
  }

  // Get Best Move Handler
  const getBestMove = async (file, whiteToMove = isWhiteMove, fullHint = isFullHint) => {
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      try {
        const response = await axios.get('/api/check-credits', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        const { bestMoves, hints } = response.data
        if ((isFullHint && bestMoves <= 0) || (!isFullHint && hints <= 0)) {
          setCreditsPopup({ show: true, type: isFullHint ? 'best moves' : 'hints' })
          return
        }
        const formData = new FormData()
        formData.append('file', file)
        formData.append('isWhiteMove', whiteToMove.toString())
        formData.append('isFullHint', fullHint.toString())
        const uploadResponse = await axios.post('/api/get-best-move', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('token')
          }
        })
        setUploadResponse(uploadResponse.data)
        setNewUpload(true) // Mark as a new upload
        if (window.innerWidth <= 768 && responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error(error)
          setInvalidFenPopup(true)
        } else {
          console.error('this is the error:', error)
        }
      }
    }
  }

  // Get Analysis Handler
  const getAnalysis = async () => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (
      newUpload &&
      uploadResponse &&
      uploadResponse.new_fen &&
      uploadResponse.old_fen &&
      uploadResponse.best_move &&
      uploadResponse.evaluation &&
      uploadResponse.pv
    ) {
      try {
        setIsGettingAnalysis(true)
        const response = await axios.get('/api/check-credits', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        const { analyses } = response.data
        if (analyses <= 0) {
          setCreditsPopup({ show: true, type: 'analyses' })
          setIsGettingAnalysis(false)
          return
        }

        const analysisResponse = await axios.post(
          '/api/analysis',
          {
            new_fen: uploadResponse.new_fen,
            old_fen: uploadResponse.old_fen,
            best_move: uploadResponse.best_move,
            evaluation: uploadResponse.evaluation,
            pv: uploadResponse.pv,
            generate_audio: withAudio
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token')
            }
          }
        )
        setAnalysisResponse(analysisResponse.data)
        setNewUpload(false)
        setIsGettingAnalysis(false)

        if (window.innerWidth <= 768 && responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth' })
        }

        if (analysisResponse.data.audioUrl) {
          setAudioUrl(analysisResponse.data.audioUrl)
        }
      } catch (error) {
        setIsGettingAnalysis(false)
        console.error(error)
      }
    }
  }

  // Analyze Simulated Move Handler
  const analyzeSimulatedMove = async (whiteToMove, fromBoard, move) => {
    if (!isLoggedIn) {
      navigate('/login')
      return
    }

    if (!move && !fromBoard) {
      alert('Please enter a move in UCI notation (e.g., e2e4)')
      return
    }

    try {
      setIsAnalyzingMove(true)
      const response = await axios.get('/api/check-credits', {
        headers: { 'x-access-token': localStorage.getItem('token') }
      })

      const { analyses } = response.data
      if (analyses <= 0) {
        setCreditsPopup({ show: true, type: 'analyses' })
        setIsAnalyzingMove(false)
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('isWhiteMove', whiteToMove.toString())

      const fenResponse = await axios.post('/api/get-fen', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': localStorage.getItem('token')
        }
      })

      console.log('move to analyze:', move)

      // Then analyze the move
      const analysisResponse = await axios.post(
        '/api/analyze-move',
        {
          fen: fenResponse.data.fen,
          move: move,
          isWhiteMove: whiteToMove.toString(),
          generate_audio: withSimulatedMoveAudio
        },
        {
          headers: {
            'x-access-token': localStorage.getItem('token')
          }
        }
      )

      setSimulatedMoveResponse(analysisResponse.data)
      setAnalysisResponse(null) // Clear previous best move analysis

      // Handle audio if available
      if (analysisResponse.data.audioUrl) {
        setAudioUrl(analysisResponse.data.audioUrl)
      }

      // Scroll to response on mobile
      if (window.innerWidth <= 768 && responseRef.current) {
        responseRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    } catch (error) {
      console.error('Error analyzing move:', error)
      if (error.response?.status === 400) {
        alert('Invalid move. Please make sure the move is legal and in correct UCI notation (e.g., e2e4)')
      } else {
        alert('Error analyzing move. Please try again.')
      }
    } finally {
      setIsAnalyzingMove(false)
    }
  }

  // useEffect to trigger analysis after moveToAnalyze is updated
  useEffect(() => {
    if (moveToAnalyze) {
      // Determine if the move was from the board or input
      const fromBoard = moveSource === 'board'
      analyzeSimulatedMove(isWhiteMove, fromBoard, moveToAnalyze)
        .then(() => {
          // Reset moveToAnalyze and moveSource after analysis to prevent re-triggering
          setMoveToAnalyze('')
          setMoveSource(null)
        })
        .catch(error => {
          console.error('Error in useEffect analyzeSimulatedMove:', error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveToAnalyze, moveSource]) // Watch both moveToAnalyze and moveSource

  return (
    <div className="upload-container">
      {/* User Information and Authentication */}
      <div className="user-info">
        {loggedInEmail ? (
          <>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                ☰
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={() => navigate('/my-account')}>
                    My Account
                  </button>
                  <button className="dropdown-item" onClick={() => navigate('/create_contract')}>
                    Play a Friend
                  </button>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
            <p>Logged in as: {loggedInEmail}</p>
          </>
        ) : (
          <>
            <p>Not logged in, please log in to use.</p>
            <div className="auth-buttons">
              <button className="register-button" onClick={() => navigate('/register')}>
                Register
              </button>
              <button className="login-button" onClick={() => navigate('/login')}>
                Log In
              </button>
              <div className="google-signin-container">
                <div id="google-signin-button"></div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Popups */}
      {instructionPopup && <InstructionPopup />}
      {invalidFenPopup && <InvalidFenPopup />}
      {creditsPopup.show && <CreditsPopup type={creditsPopup.type} />}

      {/* Controls */}
      <div className="move-turn-segmented-control">
        <button
          className={`segmented-control-button ${isWhiteMove ? 'active' : ''}`}
          onClick={() => setIsWhiteMove(true)}
          type="button"
        >
          White to Move
        </button>
        <button
          className={`segmented-control-button ${!isWhiteMove ? 'active' : ''}`}
          onClick={() => setIsWhiteMove(false)}
          type="button"
        >
          Black to Move
        </button>
      </div>

      <div className="hint-mode-segmented-control">
        <button
          className={`segmented-control-button ${isFullHint ? 'active' : ''}`}
          onClick={() => setIsFullHint(true)}
          type="button"
        >
          Best Move
        </button>
        <button
          className={`segmented-control-button ${!isFullHint ? 'active' : ''}`}
          onClick={() => setIsFullHint(false)}
          type="button"
        >
          Hint
        </button>
      </div>

      {/* File Upload Area */}
      <form className="upload-form">
        <label
          className={`drag-drop-area ${isDragging ? 'dragging' : ''} ${!isLoggedIn ? 'disabled' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <p>
            Drag and drop a file here, copy and paste, or click here to select a file.
            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} disabled={!isLoggedIn} />
          </p>
          {filePreview && <img src={filePreview} alt="Preview" className="file-preview" />}
          {fileName && <p className="file-name">{fileName}</p>}
        </label>
      </form>

      {/* Chess Boards and Best Move Display */}
      {currentFen && (
        <div
          className="board-comparison"
          ref={responseRef}
          style={{
            display: 'flex',
            flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
            alignItems: 'flex-start',
            marginTop: '20px'
          }}
        >
          {/* Interactive Chessboard */}
          <div className="board-section" style={{ flex: 1, marginRight: window.innerWidth <= 768 ? '0' : '20px' }}>
            <h3>Interactive Position</h3>
            <ChessDisplay
              fen={currentFen}
              onPieceMove={move => {
                setMoveToAnalyze(move)
                setMoveSource('board') // Set the move source to 'board'
                // Removed direct call to analyzeSimulatedMove
              }}
              onReset={() => {
                setMoveToAnalyze('')
                setMoveSource(null)
                setSimulatedMoveResponse(null)
              }}
            />
            {/* Move Simulation Controls */}
            <div className="move-simulation" style={{ marginTop: '10px' }}>
              <input
                type="text"
                value={moveToAnalyze}
                onChange={e => setMoveToAnalyze(e.target.value)}
                placeholder="Enter move (e.g., e2e4)"
                className="move-input"
                disabled={!isLoggedIn || isAnalyzingMove}
                style={{ marginRight: '10px' }}
              />
              <button
                type="button"
                onClick={() => {
                  if (moveToAnalyze.trim() !== '') {
                    setMoveSource('input')
                  }
                }}
                className="analysis-button"
                disabled={!isLoggedIn || isAnalyzingMove || !moveToAnalyze.trim()}
              >
                {isAnalyzingMove ? (
                  <>
                    <div className="loading-spinner" />
                    Analyzing...
                  </>
                ) : (
                  'Analyze Simulated Move'
                )}
              </button>
              <div className="audio-checkbox-container">
                <input
                  type="checkbox"
                  id="withSimulatedMoveAudio"
                  className="audio-checkbox"
                  checked={withSimulatedMoveAudio}
                  onChange={(e) => setWithSimulatedMoveAudio(e.target.checked)}
                />
                <label htmlFor="withSimulatedMoveAudio" className="audio-label">
                  With Audio
                </label>
              </div>
            </div>
            {/* Get Best Move Button */}
            <button
              type="button"
              onClick={() => getBestMove(file, isWhiteMove, isFullHint)}
              className="action-button"
              disabled={!isLoggedIn}
              style={{ marginTop: '10px' }}
            >
              Get Best Move
            </button>
          </div>

          {/* Best Move Display */}
          {uploadResponse && (
            <div className="board-section" style={{ flex: 1, marginTop: window.innerWidth <= 768 ? '20px' : '0' }}>
              <h3>Best Move</h3>
              <img
                src={`data:image/jpeg;base64,${uploadResponse.image}`}
                alt="Best Move"
                style={{ maxWidth: '400px', width: '100%' }}
              />
              {newUpload && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
                  <button
                    type="button"
                    onClick={getAnalysis}
                    className="analysis-button action-button"
                    disabled={!isLoggedIn || isGettingAnalysis}
                  >
                    {isGettingAnalysis ? (
                      <>
                        <div className="loading-spinner" />
                        Analyzing...
                      </>
                    ) : (
                      'Get Analysis'
                    )}
                  </button>
                  <div className="audio-checkbox-container">
                    <input
                      type="checkbox"
                      id="withAudio"
                      className="audio-checkbox"
                      checked={withAudio}
                      onChange={(e) => setWithAudio(e.target.checked)}
                    />
                    <label htmlFor="withAudio" className="audio-label">
                      With Audio
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Additional Response Info */}
      {uploadResponse && (
        <div ref={responseRef} className="upload-response" style={{ marginTop: '20px' }}>
          <h3>Response Info:</h3>
          <p>FEN: {uploadResponse.new_fen}</p>
          <p>Certainty: {uploadResponse.certainty}</p>
          <p>Best Move: {uploadResponse.best_move}</p>
        </div>
      )}

      {/* Analysis Response */}
      {analysisResponse && (
        <div className="analysis-response" style={{ marginTop: '20px' }}>
          <h3>Analysis:</h3>
          <p>{analysisResponse.analysis}</p>
          <br />
          <p>Line to follow: {uploadResponse.pv.join(' ')}</p>
          {audioUrl && (
            <audio controls autoPlay={false} src={audioUrl}>
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      )}

      {/* Simulated Move Analysis Response */}
      {simulatedMoveResponse && (
        <div className="analysis-response" style={{ marginTop: '20px' }}>
          <h3>Simulated Move Analysis:</h3>
          <p>Move: {simulatedMoveResponse.move}</p>
          <p>Centipawn Loss: {simulatedMoveResponse.centipawnLoss}</p>
          <p>{simulatedMoveResponse.analysis}</p>
          {audioUrl && (
            <audio controls src={audioUrl}>
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      )}
    </div>
  )
}

export default Upload
